@use '../../styles/global' as global;

.customRadioInput {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
    &:checked {
        + .customRadioLabel {
            box-shadow: 0 0 0 0.25rem rgba(global.$primary,.5);
        }
    }
}

.customRadioLabel {
    cursor: pointer;
    padding: .5rem;
    border-radius: global.$border-radius;
    display: block;
}

.customRadioImage {
    width: 11.25rem;
    @include global.media-breakpoint-up(md) {
        padding: .75rem;
    }
    .imgWrap {
        width: 7.5rem;
        margin: 0 auto 1rem;
        overflow: hidden;
        background-color: global.$light;
        &.circle {
            border-radius: 3.75rem;
        }
        &.shadow {
            box-shadow: .125rem .25rem .25rem rgba(black, .4);
        }
    }
    .label {
        display: block;
        font-size: global.$font-size-sm;
        line-height: 1.2;
    }
}