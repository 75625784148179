@use '../styles/variables' as variables;

.loginBackground {
    background-color: #282c34;
    height: 100%;
    display: flex;
    align-items: flex-end;
    @media screen and (min-width: 768px) {
        align-items: center;
    }
}

.loginWrapper {
    position: relative;
    padding: 2rem 2rem calc(2rem + env(safe-area-inset-bottom)) 2rem;
    background-color: white;
    border-radius: 2rem 2rem 0 0;
    @media screen and (min-width: 768px) {
        width: 90%;
        max-width: 360px;
        border-radius: 1rem;
        margin: 0 auto 1rem;
    }
}

.forgottenPassword {
    margin-top: .5rem;
    text-align: center;
    font-size: .75rem;
    @media screen and (min-width: 768px) {
        position:absolute;
        bottom: -2rem;
        width: 100%;
        left: 0;
        right: 0;
    }
}

.title {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    color: variables.$primary;
    margin-bottom: 1rem;
}