@import "variables";
@import "~bootstrap/scss/bootstrap";

html,
body,
#root {
	height: 100%;
  overflow-x: hidden;
  width: 100vw;
  max-width: 100vw;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
	main {
		flex-grow: 1;
	}
}

a {
  text-decoration: none;
}

.horizontal-scroll {
  padding: .25rem 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}